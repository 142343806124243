import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import "./Header.css";
import Nav from "react-bootstrap/Nav";
import Cookies from "universal-cookie";
import Tippy from "@tippyjs/react";
import { HashLink } from "react-router-hash-link";

import { BsArrowRight } from "react-icons/bs";
import { FaBars } from 'react-icons/fa';
import "tippy.js/dist/tippy.css"; // optional

import { FaRegEdit } from "react-icons/fa";
import Navbar from "react-bootstrap/Navbar";

import logo from "./../../images/logo-crypto.png";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link,useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
const cookies = new Cookies();
const Header = () => {
  const navigate=useNavigate();
  const [token, setToken]=useState(localStorage.getItem('loggedin'));
  const [navbarColor, setNavbarColor] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setNavbarColor(true);
    } else {
      setNavbarColor(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  const logoutClicked = () => {
    localStorage.removeItem("loggedin");
    localStorage.removeItem("token");

    setToken(null);
    // cookies.remove('_id', { path: '/' })
    
    navigate("/user/login");
  };


  const handleToggle = () =>{
    setExpanded(!expanded);

    console.log("Clicked")
  };

  useEffect(() => {
    const checkTokenExpiry = () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Math.floor(Date.now() / 1000); // Convert milliseconds to seconds
          if (decodedToken.exp < currentTime) {
            // Token is expired
            localStorage.removeItem("token"); // Remove the expired token
            localStorage.removeItem("loggedin");
            setToken(null); // Update the state to reflect the token removal
            navigate("/user/login"); // Redirect to login page or show a message to the user
          }
        } catch (error) {
          console.error("Error decoding token:", error);
        }
      }
    };

    const tokenCheckInterval = setInterval(checkTokenExpiry, 60000); // Check token expiry every minute

    // Clean up interval on component unmount
    return () => {
      clearInterval(tokenCheckInterval);
    };
  }, []);
  return (
    <>
      <Navbar
        bg="light"
        expand="lg"
        onToggle={handleToggle}
        expanded={expanded}
      >
        <Container fluid className={navbarColor ? "navbar active" : "navbar"}>
          <Navbar.Brand href="#" className="navbarBrand">
            <img src={logo} className="logoImg" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navbarScroll"
            style={{ color: "white" }}
          >
            <FaBars className="threeLine" />
          </Navbar.Toggle>
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className=" my-2 my-lg-0 firstNav"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Nav.Link>
                <Tippy
                  content={
                    <span className="mainContentTippy">
                      
                     
                     {/* {screenWidth<="450"? 
                    
                      <Navbar.Toggle>
                      <HashLink
                        to={screenWidth <= "450" ? "#applyHere3" : "#applyHere"}
                      >
                        <span> About</span>
                      </HashLink>

                      </Navbar.Toggle>
                      :
                       <HashLink
                       to={screenWidth <= "450" ? "#applyHere3" : "#applyHere"}
                     >
                       <span> About</span>
                     </HashLink>
                      } */}
                       {/* <Navbar.Toggle></Navbar.Toggle> */}
                      <HashLink to="/user/createEvent"
                        onClick={handleToggle}
                      >
                        <span> Add Event</span>
                      </HashLink>

                      <HashLink to="/user/eventListing"
                        onClick={handleToggle}
                      >
                        <span> View List</span>
                      </HashLink>
                    </span>
                  }
                  interactive={true}
                  placement="bottom"
                >
                  <button className="btnTippies"> Events</button>
                </Tippy>
              </Nav.Link>
              <Nav.Link>
                <Tippy
                  content={
                    <span className="mainContentTippy">
                      
                     
                     {/* {screenWidth<="450"? 
                    
                      <Navbar.Toggle>
                      <HashLink
                        to={screenWidth <= "450" ? "#applyHere3" : "#applyHere"}
                      >
                        <span> About</span>
                      </HashLink>

                      </Navbar.Toggle>
                      :
                       <HashLink
                       to={screenWidth <= "450" ? "#applyHere3" : "#applyHere"}
                     >
                       <span> About</span>
                     </HashLink>
                      } */}
                       {/* <Navbar.Toggle></Navbar.Toggle> */}
                      <HashLink to="/user/createBlog"
                        onClick={handleToggle}
                      >
                        <span> Add Blog</span>
                      </HashLink>

                      <HashLink to="/user/blogs"
                        onClick={handleToggle}
                      >
                        <span> View List</span>
                      </HashLink>
                    </span>
                  }
                  interactive={true}
                  placement="bottom"
                >
                  <button className="btnTippies"> Blogs</button>
                </Tippy>
              </Nav.Link>
              <Nav.Link>
                <Tippy
                  content={
                    <span className="mainContentTippy">
                      
                      <Link to="/user/createProject">
                        {" "}
                        <span>Add Project</span>
                      </Link>
                      <Link to="/user/projects">
                        {" "}
                        <span>View List</span>
                      </Link>

                    </span>
                  }
                  interactive={true}
                  placement="bottom"
                >
                  <button className="btnTippies"> Projects </button>
                </Tippy>
              </Nav.Link>
            </Nav>
            <Nav
              className=" my-2 my-lg-0 topLogoNav"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Nav.Link href="/">
                <img src={logo} className="logoImg logoImgHide" />
              </Nav.Link>
            </Nav>
            <Nav
              className=" my-2 my-lg-0 topLogSign"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Nav.Link className="loginNavApply">
                <Link to="/user/join/crypto"> Apply</Link>
                <BsArrowRight className="rightArrowApply" />
              </Nav.Link>
              {!token  && (
                <Nav.Link className="loginNav">
                  <Link to="/user/login">Login</Link>
                </Nav.Link>
              )}

              {!token  && (
                <Nav.Link className="loginNav">
                  <Link to="/user/signup">Signup</Link>
                </Nav.Link>
              )}

              {token && (
                <Nav.Link className="loginNav" onClick={logoutClicked}>
                  Logout
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;