import React from "react";
import arrow from './../../images/footerArrow.png'

import { AiOutlineWhatsApp } from 'react-icons/ai';
import { MdOutlineEmail } from 'react-icons/md';
import { ImLocation2 } from 'react-icons/im';
import logo from './../../images/logo-crypto 2.png'
import logo1 from './../../images/social1.png'
import logo2 from './../../images/social2.png'
import logo3 from './../../images/social3.png'
import { useNavigate } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import logo4 from './../../images/social4.png'
import logo5 from './../../images/social5.png'
import './Footer.css'
import { Link } from "react-router-dom";
const Footer = () => {
  const navigate=useNavigate();
  const privacyClicked=()=>{
    window.scrollTo(0, 0)
    navigate("/user/privacy/policy")
  }
  return (
    <>
    <div  className="mainTopContainer">
      <div className="mainContainerFooter">
        <div className="topCol1Footer">
          <img src={logo}  className="logoFooterImg" />
        
        </div>
        <div className="topRightFooter">
        <div className="secondColFooter">
          <div className="firstRowTxtHdr">Sections</div>
          <div><Link to="/user/projects">Projects</Link></div>
          <div><Link to="/user/blogs">Blogs</Link></div>
          <div><Link to="/user/eventlisting">Events</Link></div>
         
          


        </div>
        <div className="secondColFooter">
        <div  className="firstRowTxtHdr">Learn</div>
          <div> <HashLink to="/#applyHere">About</HashLink></div>
          <div><HashLink to="/#mainContainerVideo">Podcast</HashLink></div>
          <div><Link to="/user/privacy/policy">Privacy</Link></div> 
          <div><Link to="/user/contact">Contact Us</Link></div>


        </div>
        </div>


       
      </div>

      <div className="speratareFooter">

      </div>

      <div className="mainContainerFooter1">
        <div className="topCol1Footer5 ">
<div className="loginFooter">Powered by: Sollix Ventures</div>
          {/* <img src={arrow}  className="logoFooterImg1" /> */}

        
        </div>
        <div className="topRightFooter5">
        <div className="secondColFooter1">
        
          <div 
                onClick={privacyClicked}
          >Terms of use</div>
          <div className="lineVerFoot"></div>
      <div
      
      onClick={privacyClicked}
      >Privacy Policy</div>
        </div>
        <div className="secondColFooter2">

     <a href="https://www.instagram.com/thecryptohub_com/" target="_blank">   <img src={logo1}/></a>
     <a href="https://www.youtube.com/@thecryptohub1222" target="_blank">   <img src={logo2}/></a>
        
        <img src={logo3}/>
      <a href="https://www.linkedin.com/company/thecryptohubcom" target="_blank">  <img src={logo4}/></a>
      <a href="https://twitter.com/thecryptohubcom" target="_blank">   <img src={logo5}/></a>
        
        </div>
        </div>


       
      </div>

      </div>
    </>
  );
};

export default Footer;
