import React, { useState } from 'react'
import './NewsLetter.css'
import Swal from 'sweetalert2'
import axios from "axios";
import emailImg from './../../images/emailBox.png'
import { BiLoaderAlt } from 'react-icons/bi';
import { SERVERURL } from '../../ServerUrl';
const NewsLetter = () => {

  const[email,setEmail]=useState();
  const[vald,setValid]=useState();

  const[loader,setLoader]=useState(0);

  const subscribeClicked = async () => {
    if (email) {
        const data = {
            email: email
        };

        console.log("email is :", email);
        console.log("Sending request to:", `${SERVERURL}/api/v1/subscribe`);

        setLoader(1);

        try {
            const response = await axios.post(
                `${SERVERURL}/api/v1/subscribe`,
                data
            );

            console.log("Response:", response);

            setLoader(0);

            if (response.data.success === true) {
                Swal.fire(
                    response?.data?.message,
                    response.data.status,
                    'success'
                );
                setEmail("");
            } else {
                Swal.fire(
                    response?.data?.message,
                    response.data.status,
                    'warning'
                );
                setEmail("");
            }

        } catch (error) {
            console.error("Error:", error);
            setLoader(0);
            Swal.fire(
                'Error',
                'An error occurred while subscribing',
                'error'
            );
        }

    } else {
        Swal.fire(
          'Empty Field',
            'Please Enter Email',
            'warning'
        );
    }
}
  console.log("email",email)




  
  return (
    <>
    <div className='mainNewLLetter'>
   <div className='topLeftNews'>
   <img src={emailImg}/> 
    <div className='topMiddlNewsTxt'>
    <div className='subKepTxt'>Subscribe & stay updated on the latest Crypto news! </div>
    <div className='newsLetterTxt'>Newsletter</div>

    

    </div>
   </div>
    <div className='topInputBtn'>
      <div>

      {/* <div>Please enter email</div> */}
      <input
        placeholder='Type your Email Address '
        className='inputEmail'
        onChange={(e)=>setEmail(e.target.value)}
        value={email}
        />

      </div>
        
        <div className='btnSubscribe'
        onClick={subscribeClicked}
        >
          {loader===1?
          <BiLoaderAlt className='loaderNewLetter'/>:
            "Subscribe"}
        </div>
    </div>
    </div>
    
    </>
  )
}

export default NewsLetter